/* eslint-disable import/prefer-default-export */
const getDateFiltersSQL = (dateFilters) => {
  if (dateFilters.length === 0) return '';

  const statement = dateFilters
    .map(({ fieldName, from, to }) => {
      if (from && to) return `([${fieldName}] BETWEEN '${from}' AND '${to}')\n`;
      if (from) return `([${fieldName}] >= '${from}')\n`;
      if (to) return `([${fieldName}] <= '${to}')\n`;
      throw new Error('Invalid date filter values');
    })
    .join(' OR ');
  return `AND ${statement}`;
};

const getRegularFilterSQL = (filters) => {
  if (filters.length === 0) return '';

  const statement = filters.map(({ fieldName, values }) => `([${fieldName}] IN ('${values.join("','")}'))`).join(' AND ');
  return `AND ${statement}`;
};

export { getDateFiltersSQL, getRegularFilterSQL };
