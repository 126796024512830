/* eslint-disable no-case-declarations */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
// import engineWorker from 'Data/worker';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getHeaders, getTableData } from './functions/dataHelpers';
import OrbitalDataTable from './OrbitalDataTable';

const OrbitalDataTableContainer = (props) => {
  // get all filter values
  const controls = useSelector((store) => store.controlsReducer && store.controlsReducer[props.module] && store.controlsReducer[props.module][props.subModule]);
  // console.log({ controls });
  const dataInfo = useSelector((store) => store.dataReducer && store.dataReducer[props.module] && store.dataReducer[props.module][props.subModule]?.dataInfo);
  // console.log(dataInfo);

  // DERIVED STATE
  const nbDimensions = props.tableDefinition[0].groupByDimensions.length;
  const { tableName, calculatedMetrics } = props.datasetParams;
  const headers_raw = props.tableDefinition.map((tableDef) => tableDef.columnHeaders);
  const { headers, headerKeys } = getHeaders(headers_raw, nbDimensions);

  const filters = [];
  for (const [fieldName, filter] of Object.entries(controls)) {
    if (filter.selection?.length > 0 || filter.selection?.from || filter.selection?.to) {
      let type = 'list';
      if (filter.selection?.from || filter.selection?.to) type = 'date';
      filters.push({ fieldName, type, values: filter.selection });
    }
  }
  const requests = props.tableDefinition.map((tableDef, colIndex) => {
    const { metric, modifier, columnFilter, columnHeaders, groupByDimensions, sortBy } = tableDef;
    return {
      colIndex,
      metric: calculatedMetrics[metric] || metric, // if metric is a calculated metric, replace with the numerator and denominator
      modifier,
      filters,
      columnFilter,
      columnHeaders,
      groupByDimensions,
      sortBy,
    };
  });

  const { isPending, isError, isSuccess, data, error } = useQuery({
    queryKey: [props.tableKey, dataInfo, controls],
    queryFn: async () => getTableData(requests, tableName),
  });

  // UPDATE DATA WHEN CONTROLS CHANGE
  // React.useEffect(() => {
  // Don't ask for data if there is no data
  // if (dataInfo) {
  // const filters = [];
  // for (const [fieldName, filter] of Object.entries(controls)) {
  //   if (filter.selection?.length > 0 || filter.selection?.from || filter.selection?.to) {
  //     let type = 'list';
  //     if (filter.selection?.from || filter.selection?.to) type = 'date';
  //     filters.push({ fieldName, type, values: filter.selection });
  //   }
  // }
  // const requests = props.tableDefinition.map((tableDef, colIndex) => {
  //   const { metric, modifier, columnFilter, columnHeaders, groupByDimensions, sortBy } = tableDef;
  //   return {
  //     colIndex,
  //     metric,
  //     modifier,
  //     filters,
  //     columnFilter,
  //     columnHeaders,
  //     groupByDimensions,
  //     sortBy,
  //   };
  // });
  // console.log({ requests });
  // engineWorker.postMessage({
  //   type: 'getTableData',
  //   tableKey: props.tableKey,
  //   tableName: props.datasetParams.tableName,
  //   requests,
  // });
  // }
  // }, [controls, dataInfo]);

  // React to message getting the table data
  // React.useEffect(() => {
  //   engineWorker.onmessage = ({ data }) => {
  //     const { type, tableKey } = data;
  //     console.log({ type, tableKey, componenentTableKey: props.tableKey });

  //     // if (tableKey === props.tableKey) {
  //     switch (type) {
  //       case 'getTableData':
  //         const headers_raw = props.tableDefinition.map((tableDef) => tableDef.columnHeaders);
  //         const { headers, headerKeys } = getHeaders(headers_raw, nbDimensions);
  //         setTableData({ data: data.tableData, headers, headerKeys });
  //         break;
  //       default:
  //         break;
  //     }
  //     // }
  //   };
  // }, [dataInfo]); // not sure why dataInfo is needed here, but without it the message is not received on first mount

  return isSuccess ? <OrbitalDataTable data={data} headers={headers} headerKeys={headerKeys} nbDimensions={nbDimensions} /> : <p>Problem:{error?.message}</p>;
};

OrbitalDataTableContainer.propTypes = {
  tableKey: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
  tableDefinition: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string,
      modifier: PropTypes.string,
      columnFilter: PropTypes.shape({
        field: PropTypes.string,
        type: PropTypes.string,
      }),
      columnHeaders: PropTypes.arrayOf(PropTypes.string),
      groupByDimensions: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.string,
          limit: PropTypes.number,
        }),
      ),
      sortBy: PropTypes.shape({
        type: PropTypes.string,
        order: PropTypes.string,
        overrideCustomOrder: PropTypes.bool,
      }),
    }),
  ).isRequired,
  datasetParams: PropTypes.shape({
    mainFile: PropTypes.string.isRequired,
    runDetailsFile: PropTypes.string.isRequired,
    tableName: PropTypes.string,
    mainDateField: PropTypes.string,
    headersRemaps: PropTypes.objectOf(PropTypes.string),
    calculatedMetrics: PropTypes.shape({
      numerator: PropTypes.string,
      denominator: PropTypes.string,
    }),
    transformRow: PropTypes.func,
    filterDimensions: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
};

export default OrbitalDataTableContainer;
