import React from 'react';
import PropTypes from 'prop-types';
import { H4 } from '@blueprintjs/core';
import Filter from 'Components/Filter/Filter';
import DownloadDataContainer from 'Components/DownloadData/DownloadDataContainer';
import DateRangeFilter from 'Components/DateRangeFilter/DateRangeFilter';
import OrbitalSwitch from 'Components/OrbitalSwitch/OrbitalSwitch';
import LinesChartContainer from 'Components/LineChart/LinesChartContainer';

const DCOBookings = (props) => (
  <div className="DCOBookings">
    <header>
      <section className="titleRow">
        <H4>DCO Bookings</H4>
        <article className="refreshData">
          <DownloadDataContainer
            mainFile={props.mainFile}
            runDetailsFile={props.runDetailsFile}
            localLastDate={props.dataInfo.lastDate}
            onDataLoaded={props.onDataLoaded}
            showDLButton
          />
        </article>
      </section>
      <section className="topRow">
        <article className="controls">
          <section>
            <Filter module="Callisto" subModule="DCOBookings" controlName="timeGrouping" values={['Day']} placeholder="Grouping..." width={100} />
            <Filter
              module="Callisto"
              subModule="DCOBookings"
              controlName="priorYears"
              values={['PY', 'PY*', '2PY']}
              placeholder="Prior Years..."
              multiple
              width={180}
            />
            <OrbitalSwitch module="Callisto" subModule="DCOBookings" controlName="toggleCumulative" />
            <Filter module="Callisto" subModule="DCOBookings" controlName="xDimension" values={['By Book Date', 'By Lead Time']} width={140} />
          </section>
          <section>
            <span>Book Date</span>
            <DateRangeFilter module="Callisto" subModule="DCOBookings" controlName="BookDate" showDeleteSelection />
            <Filter module="Callisto" subModule="DCOBookings" controlName="BookYear" placeholder="Book Year..." multiple />
            <Filter module="Callisto" subModule="DCOBookings" controlName="BookYearMonth" placeholder="Book Year-Month..." multiple />
          </section>
          <section>
            <span>Event Date</span>
            <DateRangeFilter module="Callisto" subModule="DCOBookings" controlName="EventDate" showDeleteSelection />
            <Filter module="Callisto" subModule="DCOBookings" controlName="EventYear" placeholder="Event Year..." multiple />
            <Filter module="Callisto" subModule="DCOBookings" controlName="EventYearMonth" placeholder="Event Year-Month..." multiple />
          </section>
          <section>
            <Filter module="Callisto" subModule="DCOBookings" controlName="Channel" placeholder="Channel..." multiple />
            <Filter module="Callisto" subModule="DCOBookings" controlName="ItemType" placeholder="Item Type..." multiple />
            <Filter module="Callisto" subModule="DCOBookings" controlName="SOR" placeholder="SOR..." multiple />
            <Filter module="Callisto" subModule="DCOBookings" controlName="StateGroup" placeholder="State Group..." multiple />
          </section>
        </article>
      </section>
    </header>
    <main>
      <LinesChartContainer title="DCO Booking Curve" data={props.bookingsData} height={450} rangeSlider={props.rangeSlider} />
    </main>
  </div>
);

DCOBookings.propTypes = {
  mainFile: PropTypes.string.isRequired,
  runDetailsFile: PropTypes.string.isRequired,
  dataInfo: PropTypes.shape({
    nbRows: PropTypes.number,
    fields: PropTypes.arrayOf(PropTypes.string),
    lastDate: PropTypes.string,
  }),
  onDataLoaded: PropTypes.func.isRequired,
  bookingsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      x: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      y: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
      text: PropTypes.arrayOf(PropTypes.string),
      markers: PropTypes.bool,
      lineWidth: PropTypes.number,
      color: PropTypes.shape({
        dark: PropTypes.string,
        light: PropTypes.string,
      }),
    }),
  ).isRequired,
  rangeSlider: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

DCOBookings.defaultProps = {
  dataInfo: {},
};
export default DCOBookings;
