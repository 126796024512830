/* eslint-disable import/prefer-default-export */

import buildTableDefinition from './buildTableDefinition';

const metrics = ['BookingsUnits'];
const modifiers = ['vs. PY (%)'];
// const modifiers = ['PY', 'CY', 'vs. PY', 'vs. PY (%)'];
const sortBy = {
  type: 'CY',
  order: 'desc',
  overrideCustomOrder: false,
};
const columnFilters = [
  { name: 'Jan', fieldName: 'EventDate', type: 'date', from: '2025-01-01', to: '2025-01-31' },
  { name: 'Feb', fieldName: 'EventDate', type: 'date', from: '2025-02-01', to: '2025-02-28' },
  { name: 'Mar', fieldName: 'EventDate', type: 'date', from: '2025-03-01', to: '2025-03-31' },
  { name: 'Apr', fieldName: 'EventDate', type: 'date', from: '2025-04-01', to: '2025-04-30' },
  { name: 'May', fieldName: 'EventDate', type: 'date', from: '2025-05-01', to: '2025-05-31' },
  { name: 'Jun', fieldName: 'EventDate', type: 'date', from: '2025-06-01', to: '2025-06-30' },
  { name: 'Jul', fieldName: 'EventDate', type: 'date', from: '2025-07-01', to: '2025-07-31' },
  { name: 'Aug', fieldName: 'EventDate', type: 'date', from: '2025-08-01', to: '2025-08-31' },
  { name: 'Sep', fieldName: 'EventDate', type: 'date', from: '2025-09-01', to: '2025-09-30' },
  { name: 'Oct', fieldName: 'EventDate', type: 'date', from: '2025-10-01', to: '2025-10-31' },
  { name: 'Nov', fieldName: 'EventDate', type: 'date', from: '2025-11-01', to: '2025-11-30' },
  { name: 'Dec', fieldName: 'EventDate', type: 'date', from: '2025-12-01', to: '2025-12-31' },
  { name: 'TOTAL', fieldName: 'EventDate', type: 'date', from: '2025-01-01', to: '2025-12-31' },
];
const groupByDimensions = [
  { fieldName: 'Channel', limit: 3 },
  // { fieldName: 'ItemType', limit: 0.8 }, // if limit is less than 1, it will be a percentile (e.g. 0.8 = top 80%)
];

const DCO_BookingsByChannel_TableDefinition = buildTableDefinition(metrics, columnFilters, modifiers, groupByDimensions, sortBy);

export default DCO_BookingsByChannel_TableDefinition;
