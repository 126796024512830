import React from 'react';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import DCOBookingsContainer from './DCOBookings/DCOBookingsContainer';
import DCOReports from './DCOReports/DCOReports';
import './Callisto.css';

const CallistoContainer = (props) => (
  <div className="Callisto">
    <Switch>
      <Route exact path="/Callisto/DCO Bookings" component={DCOBookingsContainer} />
      <Route exact path="/Callisto/DCO Reports" component={DCOReports} />
    </Switch>
  </div>
);

export default CallistoContainer;
