import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import ioReducer from 'AppModules/Io/ioReducer';
import authReducer from '../Auth/authReducer';
import dataReducer from '../reducers/dataReducer';
import controlsReducer from '../reducers/controlsReducer';
import userReducer from '../reducers/userReducer';
// import dataGeneratorReducer from '../AppModules/Data/Reducers/dataGeneratorReducer';
// import uploadReducer from '../AppModules/Data/Reducers/uploadReducer';
// import datasetsReducer from '../AppModules/Data/Reducers/datasetsReducer';
// import titanReducer from '../AppModules/Titan/Reducers/titanReducer';
import ganymedeReducer from '../AppModules/Ganymede/ganymedeReducer';
import europaReducer from '../AppModules/Europa/EuropaReducer';

const reducers = combineReducers({
  authReducer,
  controlsReducer,
  dataReducer,
  userReducer,
  // dataGeneratorReducer,
  // uploadReducer,
  // datasetsReducer,
  // titanReducer,
  ganymedeReducer,
  europaReducer,
  ioReducer,
});

export default createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware)));
