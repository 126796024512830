import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Intent, Toaster } from '@blueprintjs/core';
import localforage from 'localforage';
import { useAuth0 } from 'Auth/react-auth0-spa';
import WeeklyReports from './WeeklyReports';
import ioActions from '../Actions/ioActions';
import ioAPI from '../API/ioAPI';
// import { getS3PresignedLink } from '../API/ioAPI';
import { dimensions, filterDimensions_trafficConv } from '../utils/constants';

const ioEngineToaster = Toaster.create();

const WeeklyReportsContainer = () => {
  const { user } = useAuth0();
  const fileDownLoadAccess = user[`https://orbital.hdhconsulting.be//app_metadata`]?.downloadFullData;
  const showGA_DLButton = fileDownLoadAccess?.GA_traffic_data || fileDownLoadAccess === 'all';
  const showEmail_DLButton = fileDownLoadAccess?.SFMC_Email_data || fileDownLoadAccess === 'all';

  const token = useSelector((store) => store.authReducer.token || '');
  const [downloadProgress, setDownloadProgress] = React.useState();
  const [dataInfo, setDataInfo] = React.useState({});
  const [filterValues, setFilterValues] = React.useState({});
  const [weeklyReportData, setWeeklyReportData] = React.useState({});
  const [weeklyEmailReportData, setWeeklyEmailReportData] = React.useState({});

  const { options, filtersSelection } = useSelector((store) => store.ioReducer.weeklyReports);

  const ioEngineWorker = React.useMemo(() => new Worker(new URL('../utils/ioEngineWorker.js', import.meta.url)), []);

  // Get pre-signed S3 link for Email full db file
  const emailsFileQuery = useQuery(['emailsFileLink'], () => ioAPI.getS3PresignedLink(token, 'Email/fullData.tsv'));

  // When dataEngineWorker changes, deal with it
  React.useEffect(() => {
    ioEngineWorker.onmessage = ({ data }) => {
      const { type, res } = data;
      switch (type) {
        case 'loadData':
          setDataInfo((preDataInfo) => ({
            ...preDataInfo,
            ...res,
          }));
          break;
        case 'updateFilterValues':
          setFilterValues(res.Io_trafficConversion_fullData);
          break;
        case 'getWeeklyReportData':
          if (res.Io_trafficConversion_fullData) setWeeklyReportData(res.Io_trafficConversion_fullData);
          if (res.Io_Email_reducedData) setWeeklyEmailReportData(res.Io_Email_reducedData);
          break;
        default:
          break;
      }
    };
  }, [ioEngineWorker]);

  // When mounting, load data from localForage
  React.useEffect(() => {
    const asyncLoadLocalData = async () => {
      // console.time('Loading local data');
      const localData = (await localforage.getItem('Io_trafficConversion_fullData')) || [];
      const localEmailReducedData = (await localforage.getItem('Io_Email_reducedData')) || [];
      // console.timeEnd('Loading local data');
      // console.log(localEmailReducedData[1]);
      if (localEmailReducedData.length > 0) {
        ioEngineWorker.postMessage({ table: 'Io_trafficConversion_fullData', type: 'loadData', payload: localData });
        ioEngineWorker.postMessage({ table: 'Io_Email_reducedData', type: 'loadData', payload: localEmailReducedData });
      } else {
        ioEngineToaster.show({ message: 'No data on this device.', intent: Intent.WARNING, icon: 'warning-sign' });
      }
    };
    asyncLoadLocalData();

    // When unmounting, kill the engineWorker
    return () => ioEngineWorker.terminate();
  }, []);

  // When data changes, update the filter values
  React.useEffect(() => {
    if (dataInfo.Io_trafficConversion_fullData?.nbRows > 0) {
      ioEngineWorker.postMessage({
        table: 'Io_trafficConversion_fullData',
        type: 'updateFilterValues',
        payload: { filterDimensions: filterDimensions_trafficConv },
      });
    }
  }, [dataInfo]);

  // Update filteredData when dataInfo, filterValues, filtersSelection, timeGrouping, priorYears or toggleCumulative changes
  React.useEffect(() => {
    if (dataInfo.Io_trafficConversion_fullData?.nbRows > 0 && Object.entries(filterValues).length > 0 && Object.entries(filtersSelection).length > 0) {
      const metrics = dataInfo.Io_trafficConversion_fullData.fields.filter((el) => !dimensions.includes(el));
      const filters = filtersSelection;
      ioEngineWorker.postMessage({
        table: 'Io_trafficConversion_fullData',
        type: 'getWeeklyReportData',
        payload: {
          table: 'Io_trafficConversion_fullData',
          dimensions: ['Channel'],
          metrics,
          filters,
          lastAvailableDate: dataInfo.Io_trafficConversion_fullData.lastDate,
          options: { ...options.global },
        },
      });
      ioEngineWorker.postMessage({
        table: 'Io_Email_reducedData',
        type: 'getWeeklyReportData',
        payload: {
          table: 'Io_Email_reducedData',
          dimensions: ['Source'],
          metrics,
          filters,
          lastAvailableDate: dataInfo.Io_Email_reducedData.lastDate,
          options: { ...options.global },
        },
      });
    }
  }, [dataInfo, filterValues, filtersSelection, options]);

  const onDownloadProgress = (progressEvent) => {
    setDownloadProgress(progressEvent.loaded / progressEvent.total);
  };

  const refreshData = async () => {
    // eslint-disable-next-line no-unused-vars
    let isSubscribed = true; // needed because the async function could resolve after the component has unmounted

    setDownloadProgress(0); // Set progress to show that download has started (instead of waiting for the first progress event)
    const { data, err } = await ioActions.downloadFullData(token, onDownloadProgress);
    const { emailReducedData, emailReducedErr } = await ioActions.downloadReducedEmailData(token, onDownloadProgress);
    // console.log({ emailReducedData });

    if (err || emailReducedErr) {
      ioEngineToaster.show({ message: `Error downloading the data file: ${err}`, intent: Intent.WARNING, icon: 'warning-sign' });
    } else {
      ioEngineWorker.postMessage({ table: 'Io_trafficConversion_fullData', type: 'loadData', payload: data });
      ioEngineWorker.postMessage({ table: 'Io_Email_reducedData', type: 'loadData', payload: emailReducedData });
      setDownloadProgress(null);
    }
    return () => {
      isSubscribed = false;
    };
  };

  const dispatch = useDispatch();

  const handleUpdateFilter = (filterName, newItems) => {
    dispatch(ioActions.updateFilter({ module: 'weeklyReports', filterName, newItems }));
  };

  return (
    <WeeklyReports
      refreshData={refreshData}
      weeklyReportData={weeklyReportData}
      weeklyEmailReportData={weeklyEmailReportData}
      filterValues={filterValues}
      downloadProgress={downloadProgress}
      dataInfo={dataInfo}
      filtersSelection={filtersSelection}
      handleUpdateFilter={handleUpdateFilter}
      emailsFileQuery={emailsFileQuery}
      showGA_DLButton={showGA_DLButton}
      showEmail_DLButton={showEmail_DLButton}
    />
  );
};

export default WeeklyReportsContainer;
