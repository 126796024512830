const buildTableDefinition = (metrics, columnFilters, modifiers, groupByDimensions, sortBy) => {
  const output = [];
  for (const metric of metrics) {
    for (const columnFilter of columnFilters) {
      const { name, ...columnFilter_rest } = columnFilter;
      for (const modifier of modifiers) {
        const columnHeaders = [];
        if (metrics.length > 1) columnHeaders.push(metric);
        if (columnFilters.length > 1) columnHeaders.push(columnFilter.name);
        if (modifiers.length > 1) columnHeaders.push(modifier);
        output.push({ metric, modifier, columnFilter: columnFilter_rest, columnHeaders, groupByDimensions, sortBy });
      }
    }
  }
  return output;
};

export default buildTableDefinition;
