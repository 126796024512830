const { format, add } = require('date-fns');
const { default: priorYearsDefinitions } = require('utils/priorYears');

const shiftDates = ({ fieldName, from, to }, priorYear) => {
  const { deltaDays } = priorYearsDefinitions[priorYear];
  return {
    fieldName,
    from: from && format(add(from, { days: deltaDays }), 'yyyy-MM-dd'),
    to: to && format(add(to, { days: deltaDays }), 'yyyy-MM-dd'),
  };
};

export default shiftDates;
