/* eslint-disable no-case-declarations */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import localforage from 'localforage';
import engineWorker from 'Data/worker';
import PropTypes from 'prop-types';
import DownLoadDataContainer from 'Components/DownloadData/DownloadDataContainer';
import { H4, Intent, Toaster } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import './DataLoader.css';
import { useMutation } from '@tanstack/react-query';
import { createSQLtable_async } from 'Data/engine';
import transformData from './utils/transformData';

const toaster = Toaster.create();

const DataLoader = (props) => {
  // VARIABLES
  const { tableName } = props.datasetParams;
  const { mainDateField, filterDimensions } = props.datasetParams;

  // STATE
  const dispatch = useDispatch();
  const [dataInfo, setDataInfo] = React.useState();

  const loadDataMutation = useMutation({
    mutationFn: (data) => createSQLtable_async({ tableName, data, params: { mainDateField, filterDimensions } }),
    onSuccess: (data, variables, context) => {
      const { nbRows, fields, lastDate, filterValues } = data[props.datasetParams.tableName];
      dispatch({
        type: 'UDPATE_DATA_INFO',
        module: props.module,
        subModule: props.subModule,
        dataInfo: { nbRows, fields, lastDate },
      });
      dispatch({
        type: 'UPDATE_ALL_CONTROL_VALUES',
        module: props.module,
        subModule: props.subModule,
        filterValues,
      });
      setDataInfo({ nbRows, fields, lastDate });
    },
  });

  // LOAD DATA ON MOUNT
  React.useEffect(() => {
    engineWorker.onmessage = ({ data }) => {
      const { type } = data;
      switch (type) {
        case 'loadData':
          const { nbRows, fields, lastDate, filterValues } = data.loadDataOutput[props.datasetParams.tableName];
          dispatch({
            type: 'UDPATE_DATA_INFO',
            module: props.module,
            subModule: props.subModule,
            dataInfo: { nbRows, fields, lastDate },
          });
          dispatch({
            type: 'UPDATE_ALL_CONTROL_VALUES',
            module: props.module,
            subModule: props.subModule,
            filterValues,
          });
          setDataInfo({ nbRows, fields, lastDate });
          break;
        default:
          break;
      }
    };

    // Load Data
    const asyncLoadLocalData = async () => {
      const localData = await localforage.getItem(props.datasetParams.tableName);
      if (localData) {
        loadDataMutation.mutate(localData);
        // engineWorker.postMessage({
        //   type: 'loadData',
        //   tableName: props.datasetParams.tableName,
        //   data: localData,
        //   params: { mainDateField: props.datasetParams.mainDateField, filterDimensions: props.datasetParams.filterDimensions },
        // });
      } else {
        toaster.show({ message: 'No data on this device.', intent: Intent.WARNING, icon: 'warning-sign' });
      }
    };
    asyncLoadLocalData();
  }, []);

  const onDataLoaded = (rawData) => {
    const transformedData = transformData(rawData, props.datasetParams.headersRemaps, props.datasetParams.transformRow);
    localforage.setItem(props.datasetParams.tableName, transformedData);
    loadDataMutation.mutate(transformedData);

    // engineWorker.postMessage({
    //   type: 'loadData',
    //   tableName: props.datasetParams.tableName,
    //   data: transformedData,
    //   params: { mainDateField: props.datasetParams.mainDateField, filterDimensions: props.datasetParams.filterDimensions },
    // });
  };

  return (
    <div className="DataLoader">
      <header>
        <section className="titleRow">
          <H4>{props.pageTitle}</H4>
          <article className="refreshData">
            <DownLoadDataContainer
              mainFile={props.datasetParams.mainFile}
              runDetailsFile={props.datasetParams.runDetailsFile}
              localLastDate={dataInfo?.lastDate}
              onDataLoaded={onDataLoaded}
              showDLButton
            />
          </article>
        </section>
      </header>
      <main>{props.children}</main>
    </div>
  );
};

DataLoader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  datasetParams: PropTypes.shape({
    mainFile: PropTypes.string.isRequired,
    runDetailsFile: PropTypes.string.isRequired,
    tableName: PropTypes.string,
    mainDateField: PropTypes.string,
    headersRemaps: PropTypes.objectOf(PropTypes.string),
    transformRow: PropTypes.func,
    filterDimensions: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
};

export default DataLoader;
