/* eslint-disable no-param-reassign */

import produce from 'immer';

// const localState = localStorage.getItem('dataState');
// const loadedState = localState ? JSON.parse(localState) : {};
const loadedState = {};

const dataReducer = produce((draft = loadedState, action) => {
  if (!draft[action.module]) draft[action.module] = {};
  if (!draft[action.module][action.subModule]) draft[action.module][action.subModule] = {};

  switch (action.type) {
    case 'UDPATE_DATA_INFO':
      draft[action.module][action.subModule].dataInfo = action.dataInfo;
      break;
    default:
      break;
  }

  // localStorage.setItem('dataState', JSON.stringify(draft));
  return draft;
});

export default dataReducer;
