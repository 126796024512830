/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
import alasql from 'alasql';

const getFilterValues_sync = ({ tableName, filterDimensions }) => {
  // console.time('Getting filter values');
  const currentFilterValues = {};
  for (const filterDimension of Object.keys(filterDimensions)) {
    let uniqueValues = alasql(`
          SELECT DISTINCT [${filterDimension}]
          FROM ${tableName}
          WHERE [${filterDimension}] IS NOT NULL
          ORDER BY [${filterDimension}]
        `).map((el) => el[filterDimension]);

    // Re-order based on dataset params
    const { order, customOrder } = filterDimensions[filterDimension];
    if (customOrder) {
      uniqueValues = uniqueValues.sort((a, b) => (customOrder.indexOf(a) + 1 || 1e6) - (customOrder.indexOf(b) + 1 || 1e6));
    } else if (order === 'desc') {
      uniqueValues = uniqueValues.sort().reverse();
    } else {
      uniqueValues = uniqueValues.sort();
    }
    currentFilterValues[filterDimension] = uniqueValues;
  }

  // console.timeEnd('Getting filter values');
  return currentFilterValues;
};

const getFilterValues = async ({ tableName, filterDimensions }) => {
  console.time('Getting filter values');
  const output = {};
  const uniqueValuesQueries = Object.keys(filterDimensions).map(
    (filterDimension) =>
      `SELECT DISTINCT [${filterDimension}]
      FROM ${tableName}
      WHERE [${filterDimension}] IS NOT NULL
      ORDER BY [${filterDimension}]
  `,
  );
  const filterValues_raw = await alasql.promise(uniqueValuesQueries);
  // console.log({ filterValues_raw });

  for (const filterValues of filterValues_raw) {
    const filterDimension = Object.keys(filterValues[0])[0];
    const values = Object.values(filterValues).map((el) => el[filterDimension]);

    // Re-order based on dataset params
    const { order, customOrder } = filterDimensions[filterDimension];
    let orderedUniqueValues = [];
    if (customOrder) {
      orderedUniqueValues = values.sort((a, b) => (customOrder.indexOf(a) + 1 || 1e6) - (customOrder.indexOf(b) + 1 || 1e6));
    } else if (order === 'desc') {
      orderedUniqueValues = values.sort().reverse();
    } else {
      orderedUniqueValues = values.sort();
    }
    output[filterDimension] = orderedUniqueValues;
  }
  // console.log({ output });

  console.timeEnd('Getting filter values');

  return output;
};

export { getFilterValues_sync, getFilterValues };
