/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import alasql from 'alasql';
import { getFilterValues } from './functions/getFilterValues';
import { getDateFiltersSQL, getRegularFilterSQL } from './functions/buildStatements';
import shiftDates from './functions/shiftDates';

const measureDurations = true;

export const createSQLtable_async = async ({ tableName, data, params }) => {
  // console.log({ tableName, data, params });
  const mainDateField = params.mainDateField || 'Date';

  if (measureDurations) console.time('Creating Alasql table');
  // Main Table
  // prettier-ignore
  await alasql.promise([
    `DROP TABLE IF EXISTS ${tableName}`,
    `CREATE TABLE ${tableName}`,
    [`SELECT * INTO ${tableName} FROM ?`, [data]]
  ]);

  if (measureDurations) console.timeEnd('Creating Alasql table');

  // Get basic data info
  const nbRows = (await alasql.promise(`SELECT COUNT(*) AS [count] FROM ${tableName}`))[0].count;
  const fields_raw = await alasql.promise(`SELECT TOP 1 FROM ${tableName}`);
  const fields = Object.keys(fields_raw[0]);
  // console.log({ fields });

  const lastDate_raw = await alasql.promise(`SELECT TOP 1 [${mainDateField}] FROM ${tableName} ORDER BY [${mainDateField}] DESC`);
  // console.log({ lastDate_raw });
  const lastDate = lastDate_raw[0][mainDateField];

  const filterValues = await getFilterValues({ tableName, filterDimensions: params.filterDimensions });

  // console.log({ [tableName]: { nbRows, fields, lastDate, filterValues } });

  return { [tableName]: { nbRows, fields, lastDate, filterValues } };
};

export const getColumnData = ({ tableName, request }) => {
  const { metric, modifier, filters, columnFilter, groupByDimensions } = request;
  // console.log({ request });

  // Metric Statement
  const metricsStatement =
    typeof metric === 'string'
      ? `SUM([${metric}]) AS [${metric}]`
      : `SUM([${metric.numerator}]) AS [${metric.numerator}],
      SUM([${metric.denominator}]) AS [${metric.denominator}] `;
  // console.log({ metricsStatement });

  // Regular List filters
  const listFilters = filters.filter((el) => el.type === 'list');
  const regularFiltersSQL = getRegularFilterSQL(listFilters);

  // Column Filter (must be a date filter)
  const { fieldName, from, to } = columnFilter;
  const dateFilters = filters.filter((el) => el.type === 'date');
  // console.log({ dateFilters });

  const filterGroups = [];
  const colFilter = { fieldName, from, to };
  const otherDateFilters = dateFilters.map((el) => ({ fieldName: el.fieldName, from: el.values.from, to: el.values.to }));
  // console.log({ otherDateFilters });

  if (modifier === 'CY') {
    filterGroups.push({ colFilter, otherDateFilters });
  } else {
    const priorYear = modifier.replace('vs. ', '').replace(' (%)', '');
    if (modifier.indexOf('vs. ') >= 0) {
      // if modifier is a delta, we need both CY and the prior year
      filterGroups.push({ colFilter, otherDateFilters });
      filterGroups.push({ colFilter: shiftDates(colFilter, priorYear), otherDateFilters: otherDateFilters.map((el) => shiftDates(el, priorYear)) });
    } else {
      // modifier is a prior year, we only need the prior year
      filterGroups.push({ colFilter: shiftDates(colFilter, priorYear), otherDateFilters: otherDateFilters.map((el) => shiftDates(el, priorYear)) });
    }
  }

  // const dateFiltersSQL = getDateFiltersSQL(dateFilters);
  const dimensionsSQL = groupByDimensions.map((groupByDimension) => `[${groupByDimension.fieldName}]`).join(', ');

  // Query
  const queries = filterGroups.map((filterGroup) => {
    const colFilterSQL = getDateFiltersSQL([filterGroup.colFilter]);
    const otherDateFilterSQL = getDateFiltersSQL(filterGroup.otherDateFilters);
    return `
    SELECT 
      ${dimensionsSQL},
      ${metricsStatement}
    FROM ${tableName}
    WHERE 1=1
    ${colFilterSQL}
    ${otherDateFilterSQL}
    ${regularFiltersSQL}
    GROUP BY
      ${dimensionsSQL}
    ORDER BY
      ${dimensionsSQL}
  `;
  });

  // for (const query of queries) {
  //   console.log(query);
  // }

  // Run queries
  const data = queries.map((query) => alasql(query));
  // console.log({ data });
  return {
    data,
    request,
  };
};

export const getData = ({ tableName, request }) => {
  // console.log({ request });

  const { name, metric, dimension, options, filters, dateFilters } = request;

  if (options.timeGrouping[0] !== 'Day') return [[], []];

  /** *******************************
   *****   Build Date Filters   *****
   ******************************* */
  const joinedAllDateFiltersSQL = Object.entries(dateFilters)
    .filter(([fieldName, fieldFilters]) => fieldFilters.flat().length > 0) // Skips cases where filter is made of empty arrays only
    .map(([fieldName, fieldFilters]) => {
      const singleDateFiltersSQL = fieldFilters
        .filter((el) => el.length !== 0) // Skips cases where this filter an empty array
        .map((fieldFilter) => {
          const fieldFilterSQL = fieldFilter.filter((el) => el.length !== 0).map((dates) => `([${fieldName}] BETWEEN '${dates.from}' AND '${dates.to}')\n`);
          const joinedDateFilterSQL = fieldFilterSQL.join(' OR ');
          return `(${joinedDateFilterSQL})`;
        });
      const joinedSingleDateFiltersSQL = singleDateFiltersSQL.join(' AND ');
      return `(${joinedSingleDateFiltersSQL})`;
    });
  const allDateFiltersSQL = joinedAllDateFiltersSQL.join(' AND ');
  const allDateFiltersSQL_str = allDateFiltersSQL ? `AND ${allDateFiltersSQL}` : '';

  /** *********************************
   *****   Build Regular Filters   *****
   ********************************* */
  const allFiltersSQL_str = getRegularFilterSQL(filters);

  const query = `
    SELECT 
      [${dimension}],
      SUM([${metric}]) AS [${metric}]
    FROM ${tableName}
    WHERE 1=1
    ${allDateFiltersSQL_str}
    ${allFiltersSQL_str}
    GROUP BY
      ${dimension}
    ORDER BY
      ${dimension}
  `;

  // console.log(query);

  const data = alasql(query);

  // convert to arrays
  const dimensions = [];
  let metrics = [];
  for (const dataPoint of data) {
    dimensions.push(dataPoint[dimension]);
    metrics.push(dataPoint[metric]);
  }

  // Cumulative
  if (options.cumulative) {
    // prettier-ignore
    const cumulativeSum = (sum => value => sum += value)(0);
    metrics = metrics.map(cumulativeSum);
  }

  return { name, dimensions, metrics, request };
};
