import React from 'react';
import PropTypes from 'prop-types';
import { HTMLTable } from '@blueprintjs/core';
import './OrbitalDataTable.css';
import numeral from 'numeral';

const OrbitalDataTable = (props) => (
  <div className="OrbitalDataTable">
    <HTMLTable bordered interactive width="100%" style={{ fontSize: 12 }}>
      <thead>
        {props.headers.map((headerRow) => (
          <tr key={headerRow.map((el) => el.value).join('')}>
            {headerRow.map((header, colNb) => (
              <th key={props.headerKeys[colNb]} className="rowHeader" colSpan={header.colSpan || 1}>
                {header.value}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {props.data.map((row) => (
          <tr key={row.join('')} className={row.includes('Total') ? 'totalRow' : ''}>
            {row.map((cell, colNb) => {
              const { value, format } = cell;
              const cellFormated = !Number.isFinite(value) || Number.isNaN(value) ? '-' : numeral(value).format(format);
              return (
                <td
                  key={`${props.headerKeys[colNb]}_${value}`}
                  className={`${value < 0 ? 'negative' : ''} ${colNb < props.nbDimensions ? 'columnHeader' : ''}`}
                >
                  {colNb < props.nbDimensions ? cell : cellFormated}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  </div>
);

OrbitalDataTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          format: PropTypes.string,
        }),
      ]),
    ),
  ).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.arrayOf(
      // PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        colSpan: PropTypes.number,
      }),
    ),
  ).isRequired,
  headerKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  nbDimensions: PropTypes.number.isRequired,
};

export default OrbitalDataTable;
