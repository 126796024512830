/* eslint-disable import/prefer-default-export */

import buildTableDefinition from './buildTableDefinition';

const metrics = ['SalesPerCap'];
// const modifiers = ['vs. PY (%)'];
const modifiers = ['PY', 'CY', 'vs. PY', 'vs. PY (%)'];
const sortBy = {
  type: 'CY',
  order: 'desc',
  overrideCustomOrder: false,
};
const columnFilters = [
  { name: 'M-2', fieldName: 'EventDate', type: 'date', from: '2024-12-01', to: '2024-12-31' },
  { name: 'M-1', fieldName: 'EventDate', type: 'date', from: '2025-01-01', to: '2025-01-31' },
  { name: 'W-3', fieldName: 'EventDate', type: 'date', from: '2025-01-14', to: '2025-01-20' },
  { name: 'W-2', fieldName: 'EventDate', type: 'date', from: '2025-01-21', to: '2025-01-27' },
  { name: 'LW', fieldName: 'EventDate', type: 'date', from: '2025-01-28', to: '2025-02-03' },
];
const groupByDimensions = [
  { fieldName: 'Channel', limit: 3 },
  // { fieldName: 'ItemType', limit: 0.8 }, // if limit is less than 1, it will be a percentile (e.g. 0.8 = top 80%)
];

const DCO_SalesPerCapByChannel_TableDefinition = buildTableDefinition(metrics, columnFilters, modifiers, groupByDimensions, sortBy);

export default DCO_SalesPerCapByChannel_TableDefinition;
