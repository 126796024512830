const transformData = (rawData, headersRemaps, transformRow) => {
  // Convert to Array of Object (for consumption in AlaSQL)
  const [headers, ...valuesRaw] = rawData;
  const headersRemapped = headers.map((el) => headersRemaps[el] || el);

  // Remove last row if it's just an empty row
  const lastRow = valuesRaw.slice(-1);
  const values = lastRow.length === 1 && lastRow[0] === '' ? valuesRaw : valuesRaw.slice(0, valuesRaw.length - 1);

  const data = values.map((el) => {
    const currentRow = {};

    for (let i = 0; i < headersRemapped.length; i += 1) {
      currentRow[headersRemapped[i]] = ['0', '0.0', '0.00', '0.000'].includes(el[i]) ? 0 : +el[i] || el[i]; // converting numbers
    }

    // Transform values
    const transformedRow = transformRow(currentRow);

    return transformedRow;
  });

  return data;
};

export default transformData;
