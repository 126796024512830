import React from 'react';
import DataLoader from 'Components/DataLoader/DataLoader';
import Filter from 'Components/Filter/Filter';
import DateRangeFilter from 'Components/DateRangeFilter/DateRangeFilter';
import OrbitalDataTableContainer from 'Components/OrbitalDataTable/OrbitalDataTableContainer';
import datasetParams from '../utils/datasetParams';
import DCO_Bookings_TableDefinition from './tableDefinitions/DCO_BookingsByChannel_TableDefinition';
import DCO_SalesByChannel_TableDefinition from './tableDefinitions/DCO_SalesByChannel_TableDefinition';
import DCO_SalesPerCapByChannel_TableDefinition from './tableDefinitions/DCO_SalesPerCapByChannel_TableDefinition';

const DCOReports = (props) => (
  <DataLoader module="Callisto" subModule="DCO_Reports" pageTitle="DCO Reports" datasetParams={datasetParams}>
    <section className="topRow">
      <article className="controls">
        <section>
          <span>Book Date</span>
          <DateRangeFilter module="Callisto" subModule="DCO_Reports" controlName="BookDate" showDeleteSelection />
          <Filter module="Callisto" subModule="DCO_Reports" controlName="BookYear" placeholder="Book Year..." multiple />
          <Filter module="Callisto" subModule="DCO_Reports" controlName="BookYearMonth" placeholder="Book Year-Month..." multiple />
        </section>
        <section>
          <span>Event Date</span>
          <DateRangeFilter module="Callisto" subModule="DCO_Reports" controlName="EventDate" showDeleteSelection />
          <Filter module="Callisto" subModule="DCO_Reports" controlName="EventYear" placeholder="Event Year..." multiple />
          <Filter module="Callisto" subModule="DCO_Reports" controlName="EventYearMonth" placeholder="Event Year-Month..." multiple />
        </section>
        <section>
          <Filter module="Callisto" subModule="DCO_Reports" controlName="Channel" placeholder="Channel..." multiple />
          <Filter module="Callisto" subModule="DCO_Reports" controlName="ItemType" placeholder="Item Type..." multiple />
          <Filter module="Callisto" subModule="DCO_Reports" controlName="SOR" placeholder="SOR..." multiple />
          <Filter module="Callisto" subModule="DCO_Reports" controlName="StateGroup" placeholder="State Group..." multiple />
        </section>
      </article>
    </section>
    <main>
      <p>Sales per cap</p>
      <OrbitalDataTableContainer
        tableKey="DCO_SalesPerCapByChannel_TableDefinition"
        module="Callisto"
        subModule="DCO_Reports"
        datasetParams={datasetParams}
        tableDefinition={DCO_SalesPerCapByChannel_TableDefinition}
      />
      <p> </p>
      <p>Sales</p>
      <OrbitalDataTableContainer
        tableKey="DCO_SalesByChannel_TableDefinition"
        module="Callisto"
        subModule="DCO_Reports"
        datasetParams={datasetParams}
        tableDefinition={DCO_SalesByChannel_TableDefinition}
      />
      <p> </p>
      <p>Bookings</p>
      <OrbitalDataTableContainer
        tableKey="DCO_Bookings_TableDefinition"
        module="Callisto"
        subModule="DCO_Reports"
        datasetParams={datasetParams}
        tableDefinition={DCO_Bookings_TableDefinition}
      />
    </main>
  </DataLoader>
);

export default DCOReports;
