import axios from 'axios';
import { APIbaseURL } from '../utils/awsUtils';

const axiosJson = axios.create({ baseURL: APIbaseURL, responseType: 'json' });
const axiosText = axios.create({ baseURL: APIbaseURL, responseType: 'text' });

const toQueryString = (params) =>
  Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

const getAxiosErrorMessage = (err) => {
  if (err.response) {
    const { status, statusText } = err.response;
    return `${status} - ${statusText}`;
  }
  if (err.request) {
    const { status, statusText } = err.request;
    return `${status} - ${statusText}`;
  }
  return err.message;
};

const downloadData = async (token, key, onDownloadProgress) => {
  // const key = options.type === 'full' ? 'trafficConversion/fullData.csv' : '';
  axiosJson.defaults.headers.common.Authorization = `Bearer ${token}`;

  // get S3 pre-signed URL
  const rawPresignedURL = await axiosJson.get(`/getS3FileURL?${toQueryString({ filePath: key })}`);
  const preSignedURL = rawPresignedURL.data;
  // console.log(preSignedURL);

  // Download the file
  try {
    const rawData = await axiosText.get(preSignedURL, { onDownloadProgress });
    const data = rawData.data.split(/\r?\n/);
    const data_array = data.map((el) => el.split(','));
    return data_array;
  } catch (err) {
    throw new Error(getAxiosErrorMessage(err));
  }
};

const downloadRunDetails = async (token, filePath = 'trafficConversion/runDetails.csv') => {
  axiosJson.defaults.headers.common.Authorization = `Bearer ${token}`;
  // get S3 pre-signed URL
  const rawPresignedURL = await axiosJson.get(`/getS3FileURL?${toQueryString({ filePath })}`);
  const preSignedURL = rawPresignedURL.data;

  // Download the file
  try {
    const rawData = await axiosText.get(preSignedURL);
    const data = rawData.data.split(/\r?\n/);
    const headers = data[0].split(',');
    const values = data[1].split(',');
    const runDetails = {};
    for (const [index, header] of headers.entries()) {
      runDetails[header] = values[index];
    }
    return { runDetails, err: null };
  } catch (err) {
    throw new Error(getAxiosErrorMessage(err));
  }
};

const getS3PresignedLink = async (token, filePath) => {
  axiosJson.defaults.headers.common.Authorization = `Bearer ${token}`;
  // get S3 pre-signed URL
  const rawPresignedURL = await axiosJson.get(`/getS3FileURL?${toQueryString({ filePath })}`);
  const preSignedURL = rawPresignedURL.data;
  return preSignedURL;
};

export default {
  downloadData,
  downloadRunDetails,
  getS3PresignedLink,
};
